export default {
	"firebase": {
		"apiKey": "AIzaSyAMOQYXSgQHaazbAAAMEoFO7xY60DnNk-M",
        "authDomain": "hellodoctor-production-303000.firebaseapp.com",
        "projectId": "hellodoctor-production-303000",
        "storageBucket": "hellodoctor-production-303000.appspot.com",
        "messagingSenderId": "24316860364",
        "appId": "1:24316860364:web:fd23b96ef53efe07772f69",
        "measurementId": "G-SWRBFNLN36"
	},
	"verificationServiceHost": "https://email-service-pusuheofiq-uc.a.run.app"
}
