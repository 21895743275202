import React from "react";
import "animate.css/animate.css";
import {hdFonts} from "./config/fonts";
import {hdColors} from "./config/colors";


export default function HomePage() {
    return (
        <div className="App" style={{backgroundColor: hdColors.secondaryDarkest}}>
            <header className="App-header" style={{flexDirection: "row"}}>
                <div style={{flex: 1, alignItems: "center"}}>
                    <i className="fas fa-user-check" style={{fontSize: 72}}/>
                    <p style={{...hdFonts.header, fontSize: 48}}>
                        HD<span style={{...hdFonts.text, fontSize: 44}}>Verify</span>
                    </p>
                </div>
            </header>
        </div>
    );
}
