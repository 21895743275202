import config from "../../../config";

export default class VerificationService {
    static VerificationServiceHost = config.verificationServiceHost;

    static async sendUserVerificationEmail(invitedUserID) {
        const sendInvitationEmailURL = `${VerificationService.VerificationServiceHost}/send-invitation-verification-email`;

        // TODO fix variable/service names bro
        const payload = {invitedUserID};

        return fetch(sendInvitationEmailURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });
    }
}
