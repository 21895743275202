import React from "react";

export default function View(props) {
    const divProps = {...props};

    delete divProps.isHidden;

    return (
        <div {...divProps} style={{display: "flex", flexDirection: "column", ...props.style}}>
            {props.children}
        </div>
    )
}
