import React from "react";
import "animate.css/animate.css";
import {useLocation} from "@reach/router";
import firebase from "firebase";
import * as jwt from "jsonwebtoken";

import Text from "../../components/Text";
import View from "../../components/View";
import {hdColors} from "../../config/colors";
import {hdFonts} from "../../config/fonts";
import HideableView from "../../components/HideableView";
import {ExpiredTokenError} from "../../../errors";
import VerificationService from "./service";

const {auth, functions} = firebase;

export default function NewUserVerificationPage(props) {
    const [verificationStatus, setVerificationStatus] = React.useState("verifying");

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const verificationToken = urlParams.get("verificationToken");
    const emailToVerify = decodeURIComponent(urlParams.get("email"));

    const doValidateToken = async () => {
        const decodedToken = jwt.decode(verificationToken);
        const expiration = new Date(decodedToken.exp * 1000);

        if (expiration < new Date()) {
            VerificationService.sendUserVerificationEmail(decodedToken.uid).catch(error => console.warn(`error sending verification email`, error));

            throw new ExpiredTokenError();
        }
    }

    const doVerifyEmail = async () => {
        await auth().signInWithCustomToken(verificationToken);

        const verifyEmail = functions().httpsCallable("verifyEmail");

        await verifyEmail({email: emailToVerify}).then(() => setVerificationStatus("verified"));
    }

    React.useEffect(() => {
        doValidateToken()
            .then(doVerifyEmail)
            .catch(error => {
                console.warn(error);
                if (error.name === "ExpiredTokenError") {
                    setVerificationStatus("expired-token");
                } else {
                    setVerificationStatus("error")
                }
            })
    }, []);

    return (
        <View className={"new-user-verification-page-container"}>
            <View className={"verification-container"}>
                <View style={{position: "relative"}}>
                    <i className="fas fa-user-check" style={{position: "absolute", fontSize: 18, color: "white", left: 12, top: 12}}/>
                </View>
                <View style={{width: "100%", alignItems: "center", justifyContent: "center", padding: 12}}>
                    <Text style={{...hdFonts.header, fontSize: 28, color: "white"}}>
                        Hello Doctor
                    </Text>
                    <Text style={{...hdFonts.extraThin, fontSize: 20, color: "white", padding: 12}}>
                        Verificación de email
                    </Text>
                    <Text style={{...hdFonts.semiBold, fontSize: 20, color: "white"}}>
                        {emailToVerify}
                    </Text>
                </View>
                <HideableView isHidden={verificationStatus !== "verifying"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.regular, fontSize: 24, color: hdColors.secondaryDarkest}}>Verificando...</Text>
                </HideableView>
                <HideableView isHidden={verificationStatus !== "invalid-email"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.semiBold, fontSize: 24, color: hdColors.negative}}>¡Email invalido!</Text>
                </HideableView>
                <HideableView isHidden={verificationStatus !== "already-verified"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.semiBold, fontSize: 24, color: hdColors.secondary}}>Tu email ya está verificado</Text>
                </HideableView>
                <HideableView isHidden={verificationStatus !== "verified"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.regular, fontSize: 24, color: hdColors.goodDark}}>¡Verificado!</Text>
                </HideableView>
                <HideableView isHidden={verificationStatus !== "error"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.semiBold, fontSize: 24, color: hdColors.negative}}>Ocurrió un error</Text>
                </HideableView>
                <HideableView isHidden={verificationStatus !== "expired-token"} className={"verification-status-container"}>
                    <Text style={{...hdFonts.semiBold, fontSize: 16, color: hdColors.negativeLight, textAlign: "center"}}>Tu enlace ha caducado. Por favor revise su correo electrónico para ver el nuevo enlace de verificación.</Text>
                </HideableView>
            </View>
        </View>
    );
}
