import React from "react";
import {Router} from "@reach/router";
import HomePage from "./HomePage";
import NewUserVerificationPage from "./routes/newUsers/NewUserVerificationPage";

export default function HomeRouter() {

    return (
        <Router>
            <HomePage path={"/"} />
            <NewUserVerificationPage path={"/newUsers"} />
        </Router>
    )
}
