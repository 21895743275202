import React from "react"
import {Router} from "@reach/router"
import "./App.css";
import HomeRouter from "./app/router";

export default function App() {
    return (
        <Router>
            <HomeRouter path={"/*"} />
        </Router>
    )
}
