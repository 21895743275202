const hdColors = {
    lightHeaderBackground: "#894bec",
    darkAccentColor: "#4a2478",
    containerBackground: "#eeeffe",
    containerBackgroundAlt: "#8484c2",
    primary: "#5D4760",
    primaryLight: "#8b768b",
    primaryLighter: "#a99aa9",
    primaryLightest: "#c7bdc7",
    secondary: "#0084F0",
    secondaryLight: "#45AAFF",
    secondaryLightOriginal: "#7dc4ff",
    secondaryLightComplement: "#FFB87D",
    secondaryLighter: "#82c6ff",
    secondaryLightest: "#ecf7ff",
    secondaryDark: "#0062B2",
    secondaryDarker: "#003154",
    secondaryDarkest: "#00294b",
    secondaryDarkestest: "#00070d",
    tertiary: "#ECE4E1",
    tertiaryLight: "#f2ebea",
    tertiaryLighter: "#f5f1ef",
    tertiaryLightest: "#f9f4f4",
    goodDarker: "#0e542d",
    goodDark: "#10810a",
    good: "#15aa0d",
    goodLight: "#99f794",
    goodLightest: "#effeee",
    bad: "#e36663",
    badLight: "#F9DFDE",
    negative: "#C52723",
    negativeLight: "#E67573",
    gynecology: "#fce0f1",
    warn: "#dee837",
    examDark: "#F06C00",
    examLight: "#FFC18E"
};

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function alpha(color, alpha) {
    if (!color) {
        return null;
    }

    const rgb = hexToRgb(color);

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export {hdColors, alpha};

